import React from "react"
import { useHomeContext } from "../../context/renters_context"
import { formatPrice } from "../../utils/helper"

const AllPeril = () => {
  const { home } = useHomeContext()
  const { allPerilDeductible, dwelling } = home

  return (
    <>
      <h3>All Peril Deductible:</h3>
      <p>
        {`In the event of a covered loss, we will pay to restore the value of your property damaged at the time of loss minus your deductible. ${
          allPerilDeductible
            ? `Your All Peril deductible is currently set at ${allPerilDeductible} of ${formatPrice(
                dwelling
              )}`
            : "You currently do not have this coverage on your policy. Please call us or reply to this email if you would like to add it."
        }`}
      </p>
      <br />
    </>
  )
}

export default AllPeril
