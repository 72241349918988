import React from "react"
import { useHomeContext } from "../../context/renters_context"
import { formatPrice } from "../../utils/helper"

const Property = () => {
  const { home } = useHomeContext()
  const { personalProperty } = home

  return (
    <>
      <h3>Personal Property Protection:</h3>
      <p>{`In the event of a covered loss, we will pay up to ${formatPrice(
        personalProperty
      )}, minus your deductible, for your personal property that is not permanently attached to your house.

        Please note that if you have electronics that are damaged or stolen from your vehicle, it would not be covered under your Home policy. You would need to request that coverage be added to your auto insurance.`}</p>
      <br />
    </>
  )
}

export default Property
