import React from "react"
import { useHomeContext } from "../../context/renters_context"

const ExtendedPremises = () => {
  const { home } = useHomeContext()
  const { extendedPremises } = home

  return (
    <>
      <h3>Extended Premises:</h3>
      <p>
        {extendedPremises === "Purchased"
          ? "You currently have Extended Premises coverage on your policy. This coverage applies if you rent out a portion of your home or if you have vacant land (not farmland)."
          : "If you rent out a portion of your house or have vacant land (not farmland) we should discuss adding Extended Premises to your policy. Please call us at [agencyphone] or respond to this email to add this coverage."}
      </p>
      <br />
    </>
  )
}

export default ExtendedPremises
