import React from "react"
import Login from "../global/Login"
import Loading from "../global/Loading"
import BackToTop from "../global/BackToTop"
import { useHomeContext } from "../../context/renters_context"
import { useAuth0 } from "@auth0/auth0-react"
import FormOne from "./FormOne"
import FormTwo from "./FormTwo"
import HomeDocument from "./HomeDocument"

const Main = () => {
  const { currentStep } = useHomeContext()
  const { isAuthenticated, isLoading } = useAuth0()

  let steps = [<FormOne />, <FormTwo />, <HomeDocument />]

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <section className="section">
        {isAuthenticated ? steps[currentStep] : <Login />}
      </section>
      {currentStep === steps.length - 1 && <BackToTop />}
    </>
  )
}

export default Main
