import React from "react"
import { useHomeContext } from "../../context/renters_context"

// HOME FORM
const FormOne = () => {
  const { home, handleHomeChange, handleNextHomeStep } = useHomeContext()

  return (
    <form className="form" onSubmit={handleNextHomeStep}>
      <div className="form-center">
        <div className="row">
          <h2>Renters</h2>

          {/* Number Input Section */}
          <div className="vehicle-container">
            <div className="field-container">
              {/* Personal Property */}
              <div className="dwelling-container">
                <label htmlFor="personalProperty" className="label">
                  <span>Personal Property Protection:</span>
                </label>
                <input
                  type="number"
                  name="personalProperty"
                  value={home.personalProperty}
                  onChange={handleHomeChange}
                  // required
                />
              </div>
              {/* Family Liability */}
              <div className="dwelling-container">
                <label htmlFor="familyLiability" className="label">
                  <span>Family Liability Protection:</span>
                </label>
                <input
                  type="number"
                  name="familyLiability"
                  value={home.familyLiability}
                  onChange={handleHomeChange}
                  // required
                />
              </div>
              {/* Guest Medical */}
              <div className="dwelling-container">
                <label htmlFor="guestMedical" className="label">
                  <span>Guest Medical Protection:</span>
                </label>
                <input
                  type="number"
                  name="guestMedical"
                  value={home.guestMedical}
                  onChange={handleHomeChange}
                  // required
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="line" />
        {/* next button */}
        <div className="btn-container">
          <button className="btn next-btn" type="submit">
            Next
          </button>
        </div>
      </div>
    </form>
  )
}

export default FormOne
