import React from "react"
import { useHomeContext } from "../../context/renters_context"

const HomeCoverage = () => {
  const { homeData, home, handleHomeChange } = useHomeContext()

  return (
    <div className="row">
      {/* home coverage input */}
      {homeData.map((item, index) => {
        const { name, title, selection } = item

        return (
          <div key={index} className="dwelling-container">
            <label htmlFor={name} className="label">
              <h3>{title}</h3>
            </label>
            <select
              // key={index}
              name={name}
              value={home.name}
              onChange={handleHomeChange}
              // required
            >
              {/* <option className="placeholder" value="">
                none
              </option> */}

              {selection.map((value, index) => {
                return (
                  <option key={index} value={value}>
                    {value}
                  </option>
                )
              })}
            </select>
          </div>
        )
      })}
    </div>
  )
}

export default HomeCoverage
